import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import LoginForm from './LoginForm';
import track from '../../../lib/track';
import Messages, { MessagesTypes } from '../../utility/Messages';

export interface Props {
  messages: MessagesTypes;
}

export const LoginDialogContents: React.FC<Props> = ({ messages }) => {
  const [tracked, setTracked] = React.useState<boolean>(false);
  const loginRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!tracked) {
      track.modalView('Login Dialog');
      setTracked(true);
    }
  }, []);

  useEffect(() => {
    if (messages.error && Object.keys(messages.error).length !== 0) {
      if (loginRef && loginRef.current) {
        loginRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }, [messages]);

  return (
    <div
      className="save-dialog-inner"
      ref={loginRef}
      style={{ height: '100%' }}
    >
      <Messages messages={messages} />
      <LoginForm />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  messages: state.messages,
});

export default connect(mapStateToProps)(LoginDialogContents);
