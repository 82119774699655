import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import ResponsiveDialog from '../../utility/ResponsiveDialog';
import LoginDialogContents from './LoginDialogContents';
import * as types from '../../../actions/actionTypes';
import { isMobile } from '../../../lib/getDeviceSize';

export interface Props {
  dispatch: any;
  open: boolean;
}

/**
 * @function LoginDialog
 * @extends {React.Component}
 */
export const LoginDialog: React.FC<Props> = ({ open, dispatch }) => {
  const handleClose = () => {
    if (open) {
      dispatch({ type: types.CloseLoginDialog });
      // Clear the signup callback if the user closes the Modal
      dispatch({
        type: types.ClearSignupCallback,
      });
    }
  };

  return (
    <ResponsiveDialog
      mediumLargerPaper={isMobile() ? false : true}
      onClose={() => handleClose()}
      open={open}
      className="save-dialog"
      closeIconStyles={{
        width: '32',
        height: '32',
      }}
      closeButtonColor="rgba(85, 85, 85, 1)"
      buttonClassName="save-dialog-closeButton"
    >
      <LoginDialogContents />
    </ResponsiveDialog>
  );
};

const mapStateToProps = (state: any) => ({
  open: state.dialogs.loginDialogOpen,
});

export default connect(mapStateToProps)(LoginDialog);
