import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Form, Field } from 'react-final-form';
import {
  Button,
  InputLabel,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { login } from '../../../actions/auth';
import { normalizeText } from '../../../lib/helpers';
import { ToriiPlum } from '../../utility/Icons';
import { Eye, EyeSlash, GoogleLogo, FacebookLogo } from 'phosphor-react';
import { withRouter } from 'next/router';

import * as types from '../../../actions/actionTypes';
import TextField from '../../utility/TextField';
import { facebookLogin, googleLogin } from '../../../actions/oauth';
import validations, { composeValidators } from '../../../lib/validation';
import { buttonStyles } from '../../../styles/base';

import styles from '../../../styles/sass/components/LoginDialog.module.scss';

export interface Props {
  classes: any;
  dispatch: Dispatch<any>;
  router: any;
  postAuthPath: string;
  utmCampaign: string;
  utmMedium: string;
  utmSource: string;
  utmTerm: string;
  entrancePath: string;
}

/**
 * Form for a user to login to their Torii account.
 * @param {type} props- lorem ipsum.
 */
export const LoginForm: React.FC<Props> = ({
  classes,
  dispatch,
  router,
  postAuthPath,
  utmCampaign,
  utmMedium,
  utmSource,
  utmTerm,
  entrancePath,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword((previousState: boolean) => !previousState);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleFacebook = () => {
    dispatch(
      facebookLogin(
        false,
        postAuthPath,
        router.pathname,
        utmCampaign,
        utmMedium,
        utmSource,
        utmTerm,
        entrancePath,
      ),
    );
  };

  const handleGoogle = () => {
    dispatch(
      googleLogin(
        false,
        postAuthPath,
        router.pathname,
        utmCampaign,
        utmMedium,
        utmSource,
        utmTerm,
        entrancePath,
      ),
    );
  };

  const forgotPassword = () => {
    dispatch({ type: types.CloseLoginDialog });
    dispatch({ type: types.OpenForgotDialog });
  };

  const needAccount = () => {
    dispatch({ type: types.CloseLoginDialog });
    dispatch({
      postAuthPath: postAuthPath,
      type: types.OpenSignupDialog,
    });
  };

  const handleSubmit = (values: any) => {
    dispatch(login(values));
  };

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit, valid }) => (
        <form
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <ToriiPlum />
          <span className="heading">Welcome back!</span>
          <span>
            Log in to get free access to property data, trends, and the best way
            to buy a home.
          </span>
          <div
            className="sign-up-dialog-form-container"
            style={{ width: '100%' }}
          >
            <div className="input-container">
              <InputLabel id="email-label" className="input-label">
                Email Address
              </InputLabel>
              <Field
                component={TextField}
                format={normalizeText}
                fullWidth
                name="email"
                type="email"
                placeholder="email@email.com"
                validate={composeValidators(
                  validations.required,
                  validations.email,
                )}
                InputProps={{
                  disableUnderline: true,
                }}
                className="input"
              />
            </div>
            <div className="input-container">
              <InputLabel id="password-label" className="input-label">
                Password
              </InputLabel>
              <Field
                component={TextField}
                format={normalizeText}
                fullWidth
                name="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                validate={composeValidators(
                  validations.minLength8,
                  validations.required,
                )}
                className="input"
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        disableRipple
                        style={{
                          backgroundColor: 'transparent',
                          marginRight: 8,
                        }}
                      >
                        {showPassword ? (
                          <EyeSlash
                            size={32}
                            color="#443461"
                            weight="regular"
                          />
                        ) : (
                          <Eye size={32} color="#443461" weight="regular" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <Button
              disabled={!valid}
              variant="contained"
              size="medium"
              className={classes.buttonMediumBlue}
              style={{
                width: '100%',
                height: 56,
                textTransform: 'capitalize',
                borderRadius: 10,
              }}
              type="submit"
              onClick={handleSubmit}
            >
              Log in
            </Button>
            <div className="center-xs middle-xs" style={{ marginTop: 16 }}>
              <p className={`${styles.accountLink} ${styles.forgotLink}`}>
                <span className={styles.accountLinkLabel}>
                  Forgot your password?
                </span>
                <Button
                  color="primary"
                  className={`${styles.accountLinkButton} ${styles.paddingLeft}`}
                  onClick={() => {
                    forgotPassword();
                  }}
                >
                  Click Here
                </Button>
              </p>
              <p className={styles.accountLink}>
                <span className={styles.accountLinkLabel}>
                  Need an account?
                </span>
                <Button
                  color="primary"
                  className={`${styles.accountLinkButton} ${styles.paddingLeft}`}
                  onClick={() => {
                    needAccount();
                  }}
                >
                  Sign Up
                </Button>
              </p>
            </div>
            <div
              style={{
                margin: '16px 0',
                border: '1px solid #ABABAB',
                width: '100%',
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <div
                className="sign-up-nav-button-container"
                style={{ marginBottom: 16, width: '100%' }}
              >
                <Button
                  className={`${classes.buttonSocialAuth} signup-button `}
                  style={{ width: '100%', height: 56 }}
                  onClick={handleGoogle}
                >
                  <GoogleLogo size={24} color="#2D2332" weight="regular" />
                  <span
                    className="auth-button-text"
                    style={{ marginLeft: '5px' }}
                  >
                    Continue with Google
                  </span>
                </Button>
              </div>
              <div
                className="sign-up-nav-button-container"
                style={{ width: '100%' }}
              >
                <Button
                  className={`${classes.buttonSocialAuth} signup-button `}
                  onClick={handleFacebook}
                  style={{ width: '100%', height: 56 }}
                >
                  <FacebookLogo size={24} color="#2D2332" weight="fill" />
                  <span
                    className="auth-button-text"
                    style={{ marginLeft: '5px' }}
                  >
                    Continue with Facebook
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
};

const mapStateToProps = (state: any) => ({
  messages: state.messages,
  postAuthPath: state.auth.postAuthPath,
  utmCampaign: state.auth.utmCampaign,
  utmMedium: state.auth.utmMedium,
  utmSource: state.auth.utmSource,
  utmTerm: state.auth.utmTerm,
  entrancePath: state.auth.entrancePath,
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(buttonStyles)(withRouter(LoginForm)));
